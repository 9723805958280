import { registerApplication, start } from "single-spa";
// ordered by path i.e activeWhen: [ ]

registerApplication({
  name: "landing",
  app: () => System.import("landing"),
  activeWhen: ["/"],
});

registerApplication({
  name: "address-search",
  app: () => System.import("address-search"),
  activeWhen: ["/address-search"],
});

registerApplication({
  name: "map-backgrounds",
  app: () => System.import("map-backgrounds"),
  activeWhen: ["/map-backgrounds"],
});

registerApplication({
  name: "map-layer",
  app: () => System.import("map-layer"),
  activeWhen: ["/map-layer"],
});

registerApplication({
  name: "reporting",
  app: () => System.import("reporting"),
  activeWhen: ["/reporting"],
});

registerApplication({
  name: "user-management",
  app: () => System.import("user-management"),
  activeWhen: ["/user-management"],
});

registerApplication({
  name: "routes",
  app: () => System.import("routes"),
  activeWhen: ["/routes"],
});

registerApplication({
  name: "delivery",
  app: () => System.import("delivery"),
  activeWhen: ["/delivery"],
});

registerApplication({
  name: "weather",
  app: () => System.import("weather"),
  activeWhen: ["/weather"],
});

registerApplication({
  name: "journey-planner",
  app: () => System.import("journey-planner"),
  activeWhen: ["/journey-planner"],
});

registerApplication({
  name: "travel-bands",
  app: () => System.import("travel-bands"),
  activeWhen: ["/travel-bands"],
});

registerApplication({
  name: "trip-cost",
  app: () => System.import("trip-cost"),
  activeWhen: ["/trip-cost"],
});

registerApplication({
  name: "coordinate-search",
  app: () => System.import("coordinate-search"),
  activeWhen: ["/coordinate-search"],
});

registerApplication({
  name: "map-tools",
  app: () => System.import("map-tools"),
  activeWhen: ["/map-tools"],
});

registerApplication({
  name: "analysis",
  app: () => System.import("analysis"),
  activeWhen: ["/analysis"],
});

registerApplication({
  name: "population-search",
  app: () => System.import("population-search"),
  activeWhen: ["/population-search"],
});

start({
  urlRerouteOnly: true,
});
